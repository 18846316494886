import React from "react"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from '../components/layout'
import Newsletter from "../components/newsletter"
import SEO from "../components/seo"


if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

export default props => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "site.menu.about" })}
      />
      <div className="header-bg header-bg-03">
        <div className="tip-container">
          <div className="tip"><FormattedMessage id="site.clickHold" /></div>
        </div>
      </div>
      <div className="container mx-auto py-16 md:py-24 px-8">
        <div className="md:w-3/4 xl:w-1/2 mx-auto">
          <p className="typo-xxl mb-8"><FormattedMessage id="about.title" /></p>
        </div>

        <div className="md:w-3/4 xl:w-1/2 mx-auto">
          <h3 className="typo-subtitle mb-2"><FormattedMessage id="about.who.title" /></h3>
          <p className="typo-text-lg mb-16"><FormattedMessage id="about.who.text" /></p>
        </div>

        <div className="container mx-auto">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div><Img className="rounded-4xl" fluid={props.data.img01.childImageSharp.fluid} /></div>
            <div><Img className="rounded-4xl" fluid={props.data.img02.childImageSharp.fluid} /></div>
            <div><Img className="rounded-4xl" fluid={props.data.img03.childImageSharp.fluid} /></div>
            <div><Img className="rounded-4xl" fluid={props.data.img04.childImageSharp.fluid} /></div>

          </div>
        </div>

        <div className="md:w-3/4 xl:w-1/2 mx-auto my-16">
          <h3 className="typo-subtitle mb-2"><FormattedMessage id="about.roots.title" /></h3>
          <p className="typo-text-lg mb-20"><FormattedMessage id="about.roots.text" /></p>
        </div>

        <div className="container mx-auto">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div><Img className="rounded-4xl" fluid={props.data.img05.childImageSharp.fluid} /></div>
            <div><Img className="rounded-4xl" fluid={props.data.img06.childImageSharp.fluid} /></div>
            <div><Img className="rounded-4xl" fluid={props.data.img07.childImageSharp.fluid} /></div>
            <div><Img className="rounded-4xl" fluid={props.data.img08.childImageSharp.fluid} /></div>
          </div>
        </div>

        <div className="md:w-3/4 xl:w-1/2 mx-auto my-16">
          <h3 className="typo-subtitle mb-2"><FormattedMessage id="about.approach.title" /></h3>
          <p className="typo-text-lg mb-16"><FormattedMessage id="about.approach.text1" /></p>
        </div>

        <div className="container mx-auto">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div><Img className="rounded-4xl" fluid={props.data.img09.childImageSharp.fluid} /></div>
            <div><Img className="rounded-4xl" fluid={props.data.img10.childImageSharp.fluid} /></div>
            <div><Img className="rounded-4xl" fluid={props.data.img11.childImageSharp.fluid} /></div>
            <div><Img className="rounded-4xl" fluid={props.data.img12.childImageSharp.fluid} /></div>

          </div>
        </div>

        <div className="md:w-3/4 xl:w-1/2 mx-auto my-16">
          <p className="typo-text-lg mb-10">
            <FormattedMessage id="about.approach.text2" />
          </p>
        </div>

        <div className="container mx-auto">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div><Img className="rounded-4xl" fluid={props.data.img13.childImageSharp.fluid} /></div>
            <div><Img className="rounded-4xl" fluid={props.data.img14.childImageSharp.fluid} /></div>
            <div><Img className="rounded-4xl" fluid={props.data.img15.childImageSharp.fluid} /></div>
            <div><Img className="rounded-4xl" fluid={props.data.img16.childImageSharp.fluid} /></div>

          </div>
        </div>

        <div className="md:w-3/4 xl:w-1/2 mx-auto mt-16">
          <p className="typo-text-lg mb-10">
            <FormattedMessage id="about.approach.text3" />
          </p>
        </div>

      </div>

      <div className="bg-img-07 w-full">
        <Newsletter />
      </div>
    </Layout>
  )
}


export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    img01: file(relativePath: { eq: "metsiktoit_img_sq_01.png" }) {
      ...fluidImage
    }
    img02: file(relativePath: { eq: "metsiktoit_img_sq_02.png" }) {
      ...fluidImage
    }
    img03: file(relativePath: { eq: "metsiktoit_img_sq_03.png" }) {
      ...fluidImage
    }
    img04: file(relativePath: { eq: "metsiktoit_img_sq_04.png" }) {
      ...fluidImage
    }
    img05: file(relativePath: { eq: "metsiktoit_img_sq_05.png" }) {
      ...fluidImage
    }
    img06: file(relativePath: { eq: "metsiktoit_img_sq_06.png" }) {
      ...fluidImage
    }
    img07: file(relativePath: { eq: "metsiktoit_img_sq_07.png" }) {
        ...fluidImage
      }
    img08: file(relativePath: { eq: "metsiktoit_img_sq_08.png" }) {
      ...fluidImage
    }
    img09: file(relativePath: { eq: "metsiktoit_img_sq_09.png" }) {
        ...fluidImage
    }
    img10: file(relativePath: { eq: "metsiktoit_img_sq_10.png" }) {
        ...fluidImage
    }
    img11: file(relativePath: { eq: "metsiktoit_img_sq_11.png" }) {
        ...fluidImage
      }
    img12: file(relativePath: { eq: "metsiktoit_img_sq_12.png" }) {
        ...fluidImage
      }
    img13: file(relativePath: { eq: "metsiktoit_img_sq_13.png" }) {
        ...fluidImage
      }
    img14: file(relativePath: { eq: "metsiktoit_img_sq_14.png" }) {
        ...fluidImage
      }
    img15: file(relativePath: { eq: "metsiktoit_img_sq_15.png" }) {
        ...fluidImage
      }
    img16: file(relativePath: { eq: "metsiktoit_img_sq_16.png" }) {
        ...fluidImage
      }
    img17: file(relativePath: { eq: "metsiktoit_img_sq_17.png" }) {
          ...fluidImage
        }
    img18: file(relativePath: { eq: "metsiktoit_img_sq_18.png" }) {
        ...fluidImage
      }
    img19: file(relativePath: { eq: "metsiktoit_img_sq_19.png" }) {
          ...fluidImage
      }
    img20: file(relativePath: { eq: "metsiktoit_img_sq_20.png" }) {
          ...fluidImage
      }
    img21: file(relativePath: { eq: "metsiktoit_img_sq_21.png" }) {
        ...fluidImage
      }
    img22: file(relativePath: { eq: "metsiktoit_img_sq_22.png" }) {
        ...fluidImage
      }
    img23: file(relativePath: { eq: "metsiktoit_img_sq_23.png" }) {
        ...fluidImage
      }
    img24: file(relativePath: { eq: "metsiktoit_img_sq_24.png" }) {
        ...fluidImage
      }
    img25: file(relativePath: { eq: "metsiktoit_img_sq_25.png" }) {
        ...fluidImage
      }
    img26: file(relativePath: { eq: "metsiktoit_img_sq_26.png" }) {
        ...fluidImage
      }
    img27: file(relativePath: { eq: "metsiktoit_img_sq_27.png" }) {
          ...fluidImage
        }
    img28: file(relativePath: { eq: "metsiktoit_img_sq_28.png" }) {
        ...fluidImage
      }
    img29: file(relativePath: { eq: "metsiktoit_img_sq_29.png" }) {
          ...fluidImage
      }
    img30: file(relativePath: { eq: "metsiktoit_img_sq_30.png" }) {
          ...fluidImage
      }
   
  }
`